.tim-kita{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: larger;
    font-weight: bold;
    font-family: sans-serif; /* Ganti dengan jenis font yang Anda inginkan */
    color: #50B197;
    width: 200px; /* Atur lebar card sesuai kebutuhan Anda */
    margin: 20px;
}
.gambar-persegi {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px; /* Atur lebar lingkaran sesuai kebutuhan Anda */
    height: 120px; /* Atur tinggi lingkaran sesuai kebutuhan Anda */
    border-radius: 50%; /* Membuat lingkaran */
    border: 2px solid #50B197; /* Tambahkan border pada lingkaran */
    overflow: hidden; /* Mengatasi gambar yang mungkin terlalu besar */
  }
  
  .gambar-persegi img {
    max-width: 100%;
    height: auto;
    border-bottom: 2px solid #50B197; /* Tambahkan garis di bagian bawah gambar */
    padding: 10px;
    margin-bottom: 10px;
  }